import type React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'

import useDevicePixelRatio from '@cms/hooks/useDevicePixelRatio'
import { useGlobalContext } from '@cms/hooks/useGlobalContext'
import { SMART_CROP_TOKENS } from '@cms/utils/constants'
import { getRenditionImageLoader, getSmartCropImageLoaderByTokenName } from '@cms/utils/utils'
import type { BaseCardProps } from '@knauf-group/ct-designs/components/core/BaseCard'
import type { CardGroupProps } from '@knauf-group/ct-designs/components/core/CardGroup'
import { CardGroup } from '@knauf-group/ct-designs/components/core/CardGroup'
import type { ImageWeb, ReferenceWeb } from '@knauf-group/ct-designs/utils/types'
import { pushRoute } from '@knauf-group/ct-designs/utils/utils'
import type {
  TypeCardEntrySkeleton,
  TypeContentCardSkeleton,
  TypeContentDamIconSkeleton,
  TypeContentDamImageSkeleton,
} from '@knauf-group/ct-shared-nextjs/web/contentful/generated-types'
import type { ContentEntry } from '@knauf-group/ct-shared-nextjs/web/utils/types'
import { linkToReferenceProps } from '@knauf-group/ct-shared-nextjs/web/utils/utils'

const CardsWrapper: React.FC<ContentEntry<TypeContentCardSkeleton>> = ({
  fields: cardContent,
}) => {
  const { devicePixelRatio } = useDevicePixelRatio()
  const { t } = useTranslation('cms', { keyPrefix: 'common.slider' })
  const router = useRouter()
  const { siteStructure } = useGlobalContext()

  if (!cardContent) {
    return null
  }

  const isProductImage = Boolean(cardContent?.renderingMode === 'ProductImages')
  const imageLoader = isProductImage
    ? getRenditionImageLoader(devicePixelRatio)
    : getSmartCropImageLoaderByTokenName(SMART_CROP_TOKENS.CF_4X3_S, devicePixelRatio)

  const formatCardListEntry = ({
    sys,
    fields: cardEntry,
  }: ContentEntry<TypeCardEntrySkeleton>): BaseCardProps => {
    const reference: ReferenceWeb = linkToReferenceProps(
      cardEntry?.primaryReference,
      siteStructure,
    )
    const image = (cardEntry?.mediaReference as ContentEntry<TypeContentDamImageSkeleton>)
      ?.fields?.image?.[0] as ImageWeb
    const icon = (cardEntry?.mediaReference as ContentEntry<TypeContentDamIconSkeleton>)?.fields
      ?.icon?.[0] as ImageWeb

    return {
      id: sys.id,
      headline: cardEntry?.headline || '',
      description: cardEntry?.text || '',
      image,
      icon,
      nextImageComponent: Image,
      imageLoader: devicePixelRatio ? imageLoader : undefined,
      reference: reference || { label: '', href: '#' },
      nextLinkComponent: Link,
      handleClick: reference
        ? () => {
            pushRoute(reference, router)
          }
        : undefined,
      showBackground: cardContent.showBackground,
      renderingMode: cardContent.renderingMode,
    }
  }

  const cardsProps: CardGroupProps = {
    headline: cardContent.headline || '',
    text: cardContent.text || '',
    showBackground: cardContent.showBackground,
    renderingMode: cardContent?.renderingMode,
    cardList: cardContent.cardEntries
      ?.filter((card) => card.fields) // Filter out draft content from contentful
      .map((cardEntry) => formatCardListEntry(cardEntry)),
    nextLabel: t('nextButtonLabel'),
    backLabel: t('backButtonLabel'),
    prefetch: false,
  }

  return <CardGroup {...cardsProps} />
}

export default CardsWrapper
